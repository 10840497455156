import { FunctionComponent, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { VehicleResult } from "_api";
import {
  formatPrice,
  getVehicleDateOrStatus,
  getVehicleStatusWording,
} from "_utils";
import { RowVehicle } from "_components";
import { useRecoilValue } from "recoil";
import { atomUser } from "_atoms";
import "./group-vehicles.scss";

type GroupVehicleProps = {
  vehicle: VehicleResult;
  isChildren?: boolean;
  onSetFullScreenPic?: (picture: string) => void;
  onClick?: () => void;
};

const GroupVehicles: FunctionComponent<GroupVehicleProps> = (props) => {
  const { t } = useTranslation();
  const vehicle = props.vehicle;
  const userLogged = useRecoilValue(atomUser);

  const [visibleConfigs, setVisibleConfigs] = useState(false);
  const expandConfigs = () => {
    setVisibleConfigs(!visibleConfigs);
  };
  const getStatusClassName = (vehicle: VehicleResult) => {
    if (vehicle.availability == "disponible_ou_en_stock_concession")
      return "row__date-container--available";
    else if (
      vehicle.availability == "disponible_en_stock_usine_ou_sous_deux_semaines"
    )
      return "row__date-container--available";
    else if (
      vehicle.availability == "commande_usine_ou_production_sous_x_semaines"
    )
      return "row__date-container--ordered";
    else if (
      vehicle.availability ==
      "prochaine_disponibilite_usine_ou_disponibilite_sous_x_semaine"
    )
      return "row__date-container--production";
    else return "row__date-container--configurable";
  };

  const getPriceByCountry = (vehicle: VehicleResult, country?: string) => {
    switch (country) {
      case "FR":
        return formatPrice(vehicle.price_fr);
      case "DE":
        return formatPrice(vehicle.price_de);
      case "ES":
        return formatPrice(vehicle.price_es);
      case "IT":
        return formatPrice(vehicle.price_it);
      case "EN":
        return formatPrice(vehicle.price_en);
      default:
        return formatPrice(vehicle.price);
    }
  };
  return (
    <div>
      <div
        className={`group-vehicles ${
          visibleConfigs ? "group-vehicles--opened" : ""
        }`}
      >
        <div className="row">
          <div className="row__brand-container">
            <div className="row__brand-title">{vehicle.brand_name}</div>
            <div className="row__config-name">{vehicle.config_name}</div>
          </div>
          <div className="row__model-container">
            <strong>{vehicle.model}</strong>
          </div>
          <div className="row__price-container">
            <div className="row__price-label">
              {t("search_result_price_label")}
            </div>
            <div className="row__price">
              {userLogged
                ? getPriceByCountry(vehicle, userLogged.country)
                : formatPrice(vehicle.price)}
            </div>
          </div>
          <div className={`row__date-container ${getStatusClassName(vehicle)}`}>
            <div className="row__status">
              {getVehicleStatusWording(vehicle, t)}
            </div>
            <div className="row__date">
              {getVehicleDateOrStatus(vehicle, true, t)}
            </div>
          </div>
          <div
            className="row__img-background-container"
            onClick={() =>
              props.onSetFullScreenPic
                ? props.onSetFullScreenPic(vehicle.image_implantationUrl)
                : null
            }
          >
            <div
              className="row__img-background"
              style={{
                backgroundImage: `url(${vehicle.image_implantationUrl}`,
              }}
            ></div>
          </div>
          <div
            className={`row-card__container__more ${
              visibleConfigs ? "row-card__container__more--opened" : ""
            }`}
            onClick={expandConfigs}
          >
            {visibleConfigs
              ? t(`vehicle_list_state_see_less`)
              : t(`vehicle_list_state_see_more`)}
          </div>
        </div>
        {vehicle.config_group?.map((group) => {
          return (
            <RowVehicle
              key={group.id}
              vehicle={group}
              isPlanningResult={false}
              onSetFullScreenPic={(picture) =>
                props.onSetFullScreenPic
                  ? props.onSetFullScreenPic(picture)
                  : null
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default memo(GroupVehicles);
