import { FunctionComponent, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { VehicleResult } from "_api";
import { Button, ButtonType } from "_components/common";
import {
  formatPrice,
  getVehicleDateOrStatus,
  getVehicleButtonWording,
  getConfiguratorLink,
  isConfiguratorLinkPresents,
  getVehicleStatusWording,
} from "_utils";
import { atomUser, atomUserRoles, atomDealers } from "_atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { PlanningCalendarCard } from "../../common/PlanningCalendar/PlanningCalendarCard/PlanningCalendarCard";
import { getVehicleConfigurability } from "../../../_utils/vehicle";
import "./row-vehicle.scss";

type RowVehicleProps = {
  vehicle: VehicleResult;
  isPlanningResult: boolean;
  onSetFullScreenPic?: (picture: string) => void;
};

const RowVehicle: FunctionComponent<RowVehicleProps> = (props) => {
  const { t } = useTranslation();
  const { vehicle, isPlanningResult, onSetFullScreenPic } = props;
  const userLogged = useRecoilValue(atomUser);
  const [dealers] = useRecoilState(atomDealers);
  const userRoles = useRecoilValue(atomUserRoles);

  const getStatusClassName = (vehicle: VehicleResult) => {
    if (vehicle.availability == "disponible_ou_en_stock_concession")
      return "row__date-container--available";
    else if (
      vehicle.availability == "disponible_en_stock_usine_ou_sous_deux_semaines"
    )
      return "row__date-container--available";
    else if (
      vehicle.availability == "commande_usine_ou_production_sous_x_semaines"
    )
      return "row__date-container--ordered";
    else if (
      vehicle.availability ==
      "prochaine_disponibilite_usine_ou_disponibilite_sous_x_semaine"
    )
      return "row__date-container--production";
    else return "row__date-container--configurable";
  };

  const openConfigurator = useCallback((vehicle: VehicleResult) => {
    const link = getConfiguratorLink(
      vehicle,
      userRoles?.country,
      dealers,
      userLogged ? userLogged.username : null
    );
    window.open(link);
  }, []);

  const getPriceByCountry = (vehicle: VehicleResult, country?: string) => {
    switch (country) {
      case "FR":
        return formatPrice(vehicle.price_fr);
      case "DE":
        return formatPrice(vehicle.price_de);
      case "ES":
        return formatPrice(vehicle.price_es);
      case "IT":
        return formatPrice(vehicle.price_it);
      case "EN":
        return formatPrice(vehicle.price_en);
      default:
        return formatPrice(vehicle.price);
    }
  };

  return (
    <div className="row">
      {isPlanningResult ? (
        <div
          className="row__img-background-container"
          onClick={() =>
            onSetFullScreenPic
              ? onSetFullScreenPic(vehicle.image_implantationUrl)
              : null
          }
        >
          <div
            className="row__img-background"
            style={{ backgroundImage: `url(${vehicle.image_implantationUrl}` }}
          ></div>
        </div>
      ) : null}
      <div className="row__brand-container">
        <div className="row__brand-title">{vehicle.brand_name}</div>
        <div className="row__config-name">{vehicle.config_name}</div>
      </div>
      <div className="row__model-container">
        <strong>{vehicle.model}</strong>

        {vehicle.availability === "disponible_ou_en_stock_concession" ||
        vehicle.availability ===
          "commande_usine_ou_production_sous_x_semaines" ? (
          <div>{`N° ${vehicle.ORNO}`}</div>
        ) : null}
      </div>
      <div className="row__price-container">
        <div className="row__price-label">{t("search_result_price_label")}</div>
        <div className="row__price">
          {userLogged
            ? getPriceByCountry(vehicle, userLogged.country)
            : formatPrice(vehicle.price)}
        </div>
      </div>
      {isPlanningResult ? (
        <div
          className={`row__date-container row__date-container--is-planning ${getStatusClassName(
            vehicle
          )}`}
        >
          <PlanningCalendarCard
            style={{
              quantity: "low",
              configurabilities: [getVehicleConfigurability(vehicle)],
            }}
          />
          <div className="row__date">
            {getVehicleDateOrStatus(vehicle, true, t)}
          </div>
        </div>
      ) : (
        <div className={`row__date-container ${getStatusClassName(vehicle)}`}>
          <div className="row__status">
            {getVehicleStatusWording(vehicle, t)}
          </div>
          <div className="row__date">
            {getVehicleDateOrStatus(vehicle, true, t)}
          </div>
        </div>
      )}
      {!isPlanningResult ? (
        <div
          className="row__img-background-container"
          onClick={() =>
            onSetFullScreenPic
              ? onSetFullScreenPic(vehicle.image_implantationUrl)
              : null
          }
        >
          <div
            className="row__img-background"
            style={{ backgroundImage: `url(${vehicle.image_implantationUrl}` }}
          ></div>
        </div>
      ) : null}
      <div className="row__btn-container">
        {isConfiguratorLinkPresents(vehicle) ? (
          <>
            {isPlanningResult ? (
              <Button
                type={ButtonType.ROUNDED}
                text={t("search_result_planning_config_button")}
                onClick={() => openConfigurator(vehicle)}
              />
            ) : (
              <Button
                type={ButtonType.ROUNDED}
                text={getVehicleButtonWording(vehicle, t)}
                onClick={() => openConfigurator(vehicle)}
              />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default memo(RowVehicle);
