import { AxiosResponse } from "axios";
import { AuthenticatedClient, StockExchangeResponseType } from "_api";
import { User } from "_types";

/*
const stockExchangeProducts: StockExchangeResponseType[]= [
  { 
    "id": "1",
    "type": "Profilé",
    "brand": "FIAT",
    "model" : "Chausson Vans Road Line 2022 V594",
    "year": "2022",
    "zipCode": "59 000",
    "pointOfSale" : "Point de Vente A",
    "commentary" : "bla bla bla"
  },
  { 
    "id": "2",
    "type": "Intégral",
    "brand": "FIAT",
    "model" : "Chausson Vans Road Line 2022 V594",
    "year": "2022",
    "zipCode": "59 000",
    "pointOfSale" : "Point de Vente B",
    "commentary" : ""
  },
  { 
    "id": "",
    "type": "profilé",
    "brand": "FIAT",
    "model" : "Chausson Vans Road Line 2022 V594",
    "year": "2022",
    "zipCode": "59 000",
    "pointOfSale" : "Point de Vente Z",
    "commentary" : "bla bla bla"
  }
]
*/

/* eslint-disable @typescript-eslint/no-unused-vars */
export const getStockExchangeProduct = (
  dealerslist: string[],
  country_user: string | undefined
): Promise<AxiosResponse<any>> => {
  const params = {
    dealerslist,
    country_user,
  };
  return AuthenticatedClient.post("/stockExchange", params);
  // return AuthenticatedClient.post('/exchange');
  /*
  return new Promise((resolve) => {
    const result = {
      data: stockExchangeProducts
    };
    resolve(result);
  });
  */
};

export const getStockExchangeProductById = (
  id: string
): Promise<AxiosResponse<StockExchangeResponseType[]>> => {
  return AuthenticatedClient.get("/exchangedetail/" + id);
};

export const validateStockExchange = (
  stock: any,
  dealerslist: string[],
  stockExchangeCommentary: string | null,
  user: User | null
): Promise<AxiosResponse<void>> => {
  let params = {};
  if (stock.infoClient) {
    params = {
      finalclient: {
        firstName: stock?.infoClient.firstName,
        lastName: stock?.infoClient.lastName,
        clientPhone: stock?.infoClient.clientPhone,
        clientEmail: stock?.infoClient.clientEmail,
        dealerslist: dealerslist,
      },
      stockExchangeCommentary: stockExchangeCommentary,
      stockExchange: true,
      ORNO: stock.ORNO,
      pointOfSale: stock.pointOfSale,
      dealerEmail: user?.username,
      dealerslist: dealerslist,
      CUNO: stock.CUNO,
      country_user: user?.country,
    };
  } else {
    params = {
      stockExchangeCommentary: stockExchangeCommentary,
      stockExchange: true,
      ORNO: stock.ORNO,
      pointOfSale: stock.pointOfSale,
      dealerEmail: user?.username,
      dealerslist: dealerslist,
      CUNO: stock.CUNO,
      country_user: user?.country,
    };
  }
  return AuthenticatedClient.post("/instock/updateinfo", params);
};

export const removeFromStockExchange = (
  stock: any,
  user: User | null
): Promise<AxiosResponse<void>> => {
  const params = {
    stockExchange: false,
    ORNO: stock.ORNO,
    dealerEmail: user?.username,
    CUNO: stock.CUNO,
    country_user: user?.country,
  };
  return AuthenticatedClient.post("/instock/updateinfo", params);
};
